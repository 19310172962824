import axios from 'axios';
import { required, email, maxLength } from 'vuelidate/lib/validators';
import Swal from 'sweetalert2';
import { validationMessage } from 'vuelidate-messages';
import Vue from 'vue';
import VueMask from 'v-mask';
import Vuelidate from 'vuelidate';
import VueRecaptcha from 'vue-recaptcha';

type ContatoDto = {
    nome: string;
    email: string;
    telefone: string;
    assunto_id: number;
    mensagem: string;
    grecaptcha: string;
};

interface Lead {
    nome?: string;
    email?: string;
    telefone?: string;
    telefone_ddi?: string;
    idsituacao?: number;
    idempreendimento?: number;
    origem: string;
    campos_adicionais?: {
        [key: string]: string;
    };
    interacoes?: {
        tipo: string;
        descricao: string;
    }[];
}

const API_URL = process.env.MIX_API_CVCRM_URL;

const TOKEN = process.env.MIX_TOKEN_CVCRM;

function initContato() {
    const contatoForm = document.querySelector<HTMLElement>('.contato__forms');
    if (contatoForm === null) {
        return;
    }
    Vue.use(Vuelidate);
    Vue.use(VueMask);

    let formMessages = {
        required: () => 'O campo é obrigatório',
        minLength: ({ $params }: any) => `O campo deve possuir no mínimo ${$params.minLength.min} caracteres.`,
        maxLength: ({ $params }: any) => `O campo deve possuir no máximo ${$params.maxLength.min} caracteres.`,
        email: () => 'O e-mail inserido é inválido',
        sameAs: () => 'Você deve aceitar as políticas de privacidade.',
    };
    let validationRules = {
        nome: {
            required,
            maxLength: maxLength(255),
        },
        email: {
            required,
            email,
        },
        telefone: {
            required,
            maxLength: maxLength(15),
        },
        assunto_id: {
            required,
        },
        mensagem: {
            required,
            maxLength: maxLength(255),
        },
        grecaptcha: {
            required,
        },
    };
    const getMask = (value: string) => {
        return value.length < 15 ? '(##) ####-####?#' : '(##) #####-####';
    };

    new Vue({
        el: contatoForm,
        data: {
            nome: '',
            email: '',
            telefone: '',
            assunto_id: null,
            mensagem: '',
            grecaptcha: null,

            loading: false,
            phoneMask: '(##) ####-####?#',
        },
        components: {
            VueRecaptcha,
        },
        validations: validationRules,
        watch: {
            telefone(value) {
                this.phoneMask = getMask(value);
            },
        },
        methods: {
            validationMsg: validationMessage(formMessages, {}),
            reset() {
                this.nome = '';
                this.email = '';
                this.telefone = '';
                this.assunto_id = null;
                this.mensagem = '';
                this.grecaptcha = null;
                this.$refs.grecaptcha.reset();
                this.$v.$reset();
            },
            treatCityString(): string {
                const city = localStorage.getItem('cidade');

                const treatedCity = city?.replace(/-/g, ' ').toUpperCase()!;

                if (treatedCity === 'SAO LUIS') {
                    return 'SÃO LUÍS';
                }

                return treatedCity;
            },
            async postLead(lead: Lead): Promise<void> {
                const city = this.treatCityString();

                lead.campos_adicionais = {
                    Loja: city,
                };
                lead.origem = 'SI';

                try {
                    await axios.post(API_URL as string, lead, {
                        headers: {
                            TOKEN: TOKEN as string,
                            'Content-Type': 'application/json',
                        },
                    });
                } catch (error) {
                    console.error('Erro ao cadastrar lead:', error);
                }
            },
            getFormData(): ContatoDto {
                return {
                    nome: this.nome,
                    email: this.email,
                    telefone: this.telefone.replaceAll(/[^0-9]/g, ''),
                    assunto_id: this.assunto_id,
                    mensagem: this.mensagem,
                    grecaptcha: this.grecaptcha,
                };
            },
            warnSubmitSuccess(): void {
                Swal.fire({
                    title: 'Sucesso!',
                    text: 'Seu contato foi feito com sucesso.',
                    showCancelButton: false,
                    confirmButtonColor: '#D0782F',
                    confirmButtonText: 'Obrigado(a)!',
                    customClass: {
                        popup: 'modal__content--sucesso',
                        header: 'modal__header modal__header--sucesso',
                        title: 'modal__title--sucesso',
                        actions: 'modal__actions',
                        confirmButton: 'modal__btn modal__sucesso',
                    },
                    buttonsStyling: false,
                });
            },
            warnSubmitFailure(): void {
                Swal.fire({
                    title: 'Ops! Houve um problema',
                    text: 'No momento não foi possível enviar seu contato, tente novamente mais tarde!',
                    showCancelButton: false,
                    confirmButtonColor: '#D0782F',
                    confirmButtonText: 'OK',
                    customClass: {
                        popup: 'modal__content--erro',
                        header: 'modal__header modal__header--erro',
                        title: 'modal__title--erro',
                        actions: 'modal__actions',
                        confirmButton: 'modal__btn modal__erro',
                    },
                    buttonsStyling: false,
                });
            },
            startLoading(): void {
                this.loading = true;
            },
            stopLoading(): void {
                this.loading = false;
            },
            formDataIsValid(): boolean {
                this.$v.$touch();
                return !this.$v.$invalid;
            },
            submitForm(): void {
                this.startLoading();

                const data = this.getFormData();

                if (this.formDataIsValid() === false) {
                    alert('Formulário inválido! Algum dado está faltando ou foi preenchido incorretamente.');
                } else {
                    axios
                        .post(/* url */ '/api/fale-conosco', /* data */ this.getFormData())
                        .then(this.warnSubmitSuccess)
                        .then(this.reset)
                        .then(this.postLead(data))
                        .catch(this.warnSubmitFailure);
                }
                this.stopLoading();
            },
            setGrecaptchaToken(grecaptchaToken: string): void {
                this.grecaptcha = grecaptchaToken;
            },
            warnRecaptchaExpiral(): void {
                Swal.fire({
                    title: 'Aviso!',
                    text: 'Sua verificação de Recaptcha expirou, tente novamente.',
                    showCancelButton: false,
                    confirmButtonColor: '#D0782F',
                    confirmButtonText: 'OK',
                    customClass: {
                        popup: 'modal__content--erro',
                        header: 'modal__header modal__header--erro',
                        title: 'modal__title--erro',
                        actions: 'modal__actions',
                        confirmButton: 'modal__btn modal__erro',
                    },
                    buttonsStyling: false,
                });
            },
            warnRecaptchaError(): void {
                Swal.fire({
                    title: 'Ops! Houve um problema',
                    text: 'A verificação do seu Recaptcha falhou!',
                    showCancelButton: false,
                    confirmButtonColor: '#D0782F',
                    confirmButtonText: 'OK',
                    customClass: {
                        popup: 'modal__content--erro',
                        header: 'modal__header modal__header--erro',
                        title: 'modal__title--erro',
                        actions: 'modal__actions',
                        confirmButton: 'modal__btn modal__erro',
                    },
                    buttonsStyling: false,
                });
            },
        },
    });
}
initContato();
